require("bootstrap");
import bootbox from './bootbox.min';

var locale;

$(document).ready(function() {
    $.ajax({
        url: "/ajax/locale",
        method: "post"
    }).done(function(msg) {
        locale = JSON.parse(msg['data']);

        window.deleteCommentMessage = function deleteCommentMessage(comment_id) {
            bootbox.confirm("Êtes-vous sûr de vouloir supprimer ce commentaire ?", function(result) {
                if (result) {
                    window.location.href = "/" + locale + '/comment/delete/' + comment_id;
                }
            });
        }
    });
});
